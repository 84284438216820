export const TEXT_PERSON_MORALE =
  " En cliquant, je donne mon accord pour que la monnaie \"EU-xxx\" devienne utlisable quand 4 validateurs (personne physique) auront validé cette création. Je reconnais que les 1000 EU-coins versés pour initier cette création ne me seront pas remboursés en cas d'echec, c'est à dire si le quota de [4] validateur n'est jamais atteint";

export const TEXT_PERSON_PHYSIQUE_CREATION =
  "En cliquant, je donne mon accord pour que la monnaie \"EU-xxx\" devienne utilisable quand 8 validateurs (personne physique) auront validé cette création. Je reconnais que les 100 EU-coins versés pour initier cette création ne me seront pas remboursés en cas d'échec, c'est-à-dire si le quota de [8] validateurs n'est jamais atteint";

export const TEXT_PERSON_PHYSIQUE_VALIDATION =
  'En cliquant, je reconnais que le montant de [ 1 ] EU-coin versé pour valider cette création ne me sera pas remboursé en cas d’échec, c’est à dire si le quota des validateurs n’est jamais atteint.';

export const TEXTÈ_CONVERSION_AFFINITAIRE =
  "En clique, je donne mon accord pour que mon compte soit débité du montant choisi en EU-coins et crédité du même montant en EU-... .Je note que l'opération inverse est possible en cas d'erreur";
export const TEXTÈ_CONVERSION_COIN =
  "En clique, je donne mon accord pour que mon compte soit débité du montant choisi en EU et crédité du même montant en EU-coins .Je note que l'opération inverse est possible en cas d'erreur";
