import { List, ListItem, ListItemText } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  AddCircle,
  ExitToApp,
} from '@material-ui/icons';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HistoryIcon from '@material-ui/icons/History';
import clsx from 'clsx';
import { MdOutlineQuiz } from 'react-icons/md';
import React, { FC, useContext } from 'react';
import { NavLink, RouteComponentProps, useHistory } from 'react-router-dom';
import {
  ACCUEIL_URL,
  HISTORY_URL,
  FAQ_URL,
  CREATION_URL,
  CONVERSION_URL,
  COMMISSIONING_URL,
  ARCHIVE_URL,
} from '../../../../constant/url';
import { AuthContext } from '../../../../provider/Authprovider';
import { Balances } from '../Balance/Balances';
import FAQ from '../FAQ/FAQ';
import { Histories } from '../Historique/History';
import { useStyles } from './style';
import logoC4W from '../../../../assets/logo/logo_chain4test.svg';
import Creation from '../../creation/Creation';
import Conversion from '../../conversion/Conversion';
import IconConversion from '../../../../assets/icon/convert.svg';
import IconInvotry from '../../../../assets/icon/archive.svg';
import IconVerified from '../../../../assets/icon/verified.svg';
import MiseEnService from '../../Commissioning/Commissioning';
import { Archiving } from '../../Archive/Archive';

export const PageAccueil: FC<RouteComponentProps> = ({
  location: { pathname },
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { logout, userInfos } = useContext(AuthContext);
  const isAdmin = userInfos?.isAdmin || false;

  const showAccueil: boolean = pathname === ACCUEIL_URL;
  const showHisitorique: boolean = pathname === HISTORY_URL;
  const showFAQ: boolean = pathname === FAQ_URL;
  const showCreation: boolean = pathname === CREATION_URL;
  const showConversion: boolean = pathname === CONVERSION_URL;
  const showCommissioning: boolean = pathname === COMMISSIONING_URL;
  const showArchive: boolean = pathname === ARCHIVE_URL;
  const goTo = (url: string) => {
    history.push(url);
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.leftColumn}>
        <Box>
          <NavLink to={ACCUEIL_URL}>
            <Box className={classes.logo}>
              <img src={logoC4W} alt="C4W" />
            </Box>
          </NavLink>
          <Box className={classes.listMenu}>
            <List
              className={classes.listMenuCntr}
              component="nav"
              aria-label="main"
            >
              {!isAdmin && (
                <ListItem
                  button
                  className={clsx(
                    classes.menuItem,
                    showAccueil ? classes.activ : classes.inactive,
                  )}
                  onClick={() => goTo(ACCUEIL_URL)}
                >
                  <Box className="menuCnt">
                    <DashboardIcon />
                    <ListItemText primary="Accueil" />
                  </Box>
                </ListItem>
              )}
              {isAdmin && (
                <ListItem
                  button
                  className={clsx(
                    classes.menuItem,
                    showCommissioning ? classes.activ : classes.inactive,
                  )}
                  onClick={() => goTo(COMMISSIONING_URL)}
                >
                  <Box className="menuCnt">
                    <img src={IconVerified} alt="invotory" />
                    <ListItemText primary="Mise en service" />
                  </Box>
                </ListItem>
              )}
              {isAdmin && (
                <ListItem
                  button
                  className={clsx(
                    classes.menuItem,
                    showArchive ? classes.activ : classes.inactive,
                  )}
                  onClick={() => goTo(ARCHIVE_URL)}
                >
                  <Box className="menuCnt">
                    <img src={IconInvotry} alt="archive" />
                    <ListItemText primary="Archive" />
                  </Box>
                </ListItem>
              )}
              {!isAdmin && (
                <ListItem
                  button
                  className={clsx(
                    classes.menuItem,
                    showCreation ? classes.activ : classes.inactive,
                  )}
                  onClick={() => goTo(CREATION_URL)}
                >
                  <Box className="menuCnt">
                    <AddCircle />
                    <ListItemText primary="Création" />
                  </Box>
                </ListItem>
              )}
              {!isAdmin && (
                <ListItem
                  button
                  className={clsx(
                    classes.menuItem,
                    showConversion ? classes.activ : classes.inactive,
                  )}
                  onClick={() => goTo(CONVERSION_URL)}
                >
                  <Box className="menuCnt">
                    <img src={IconConversion} alt="conversion" />
                    <ListItemText primary="Conversion" />
                  </Box>
                </ListItem>
              )}
              {!isAdmin && (
                <ListItem
                  button
                  className={clsx(
                    classes.menuItem,
                    showHisitorique ? classes.activ : classes.inactive,
                  )}
                  onClick={() => goTo(HISTORY_URL)}
                >
                  <Box className="menuCnt">
                    <HistoryIcon />
                    <ListItemText primary="Historique" />
                  </Box>
                </ListItem>
              )}
              {!isAdmin && (
                <ListItem
                  button
                  className={clsx(
                    classes.menuItem,
                    showFAQ ? classes.activ : classes.inactive,
                  )}
                  onClick={() => goTo(FAQ_URL)}
                >
                  <Box className="menuCnt">
                    <MdOutlineQuiz size={25} />
                    <ListItemText primary="FAQ" />
                  </Box>
                </ListItem>
              )}
            </List>
          </Box>
        </Box>
        <Box className={classes.deconnect} onClick={logout}>
          <Box className={classes.deconnectCnt}>
            <ExitToApp />
            <ListItemText primary="Déconnexion" />
          </Box>
        </Box>
      </Box>
      <Box className={classes.rightColumn}>
        {showAccueil && !isAdmin ? (
          <Balances />
        ) : showHisitorique && !isAdmin ? (
          <Histories />
        ) : showFAQ && !isAdmin ? (
          <FAQ />
        ) : showCreation && !isAdmin ? (
          <Creation />
        ) : showConversion && !isAdmin ? (
          <Conversion />
        ) : showCommissioning && isAdmin ? (
          <MiseEnService />
        ) : showArchive && isAdmin ? (
          <Archiving />
        ): (
          <Typography variant="h4" className={classes.noFound}>
            Page introuvable
          </Typography>
        )}
      </Box>
    </Box>
  );
};
