// import { makeStyles } from '@material-ui/core';

// export const useStyles = makeStyles({
//   root: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     padding: '5px',
//     borderRadius: 8,
//     width: '100%',
//     border: '1px solid #ccc',
//     margin: '5px',
//   },
//   infoContainer: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     gap: '40px',
//   },
//   cardInfo: {
//     display: 'flex',
//     flexDirection: 'row',
//     gap: '20px',
//     '&:nth-of-type(2)': {
//       marginLeft: '20px',
//     },
//   },

//   contentInfo: {
//     display: 'flex',
//     flexDirection: 'column',
//   },
//   icon: {
//     display: 'flex',
//     alignItems: 'center',
//     marginLeft: -3,
//     fontSize: '0.80em',
//   },
//   info: {
//     display: 'flex',
//     alignItems: 'center',
//     marginBottom: 4,
//   },
//   title: {
//     fontWeight: 800,
//     fontSize: '0.90em',
//   },
//   date: {
//     color: 'gray',
//     fontSize: '0.70em',
//   },
//   button: {
//     fontSize: 15,
//     height: 42,
//     letterSpacing: 0.57,
//     lineHeight: '16px',
//     textAlign: 'center',
//     margin: '1px 15px',
//     padding: 10,
//     backgroundColor: 'rgb(180, 138, 78)',
//     color: 'rgb(255, 255, 255)',
//     width: 300,
//     boxShadow: '0 4px 0px rgb(0 0 0 / 55%)',
//     border: 'none',
//     cursor: 'pointer',
//     '&:hover': {
//       backgroundColor: 'rgb(160, 120, 68)',
//     },

//     '&:active': {
//       backgroundColor: 'rgb(140, 105, 60)',
//     },
//   },
//   case: {
//     display: 'flex',
//     alignItems: 'center',
//     flex: 1,
//   },
//   imglogo: {
//     width: 60,
//     height: 60,
//     border: '1px solid #8170DA',
//     borderRadius: 16,
//   },
//   object: {
//     display: 'flex',
//     alignItems: 'center',
//     marginLeft: '20px',
//     marginRight: '20px',
//   },
//   calendarIcon: {
//     marginRight: 8,
//   },
//   check: {
//     color: '#a48c69',
//     '&.Mui-checked': {
//       color: 'rgb(180, 138, 78)',
//     },
//   },
//   bar: {
//     width: '1px',
//     height: '50px',
//     backgroundColor: '#cecece',
//     margin: '0 10px',
//   },
// });
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px',
    borderRadius: 8,
    width: '100%',
    border: '1px solid #ccc',
    margin: '5px',
    '@media (max-width: 1366px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '40px',
    '@media (max-width: 1366px)': {
      flexDirection: 'column',
      gap: '20px',
      width: '100%',
    },
  },
  cardInfo: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    '&:nth-of-type(2)': {
      marginLeft: '20px',
    },
    '@media (max-width: 1366px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginLeft: '0',
    },
  },
  contentInfo: {
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1366px)': {
      width: '100%',
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -3,
    fontSize: '0.80em',
    '@media (max-width: 1366px)': {
      marginLeft: '0',
    },
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 4,
    '@media (max-width: 1366px)': {
      marginBottom: '10px',
    },
  },
  title: {
    fontWeight: 800,
    fontSize: '0.90em',
    '@media (max-width: 1366px)': {
      fontSize: '1em',
    },
  },
  date: {
    color: 'gray',
    fontSize: '0.70em',
    '@media (max-width: 1366px)': {
      fontSize: '0.80em',
    },
  },
  button: {
    fontSize: 15,
    height: 42,
    letterSpacing: 0.57,
    lineHeight: '16px',
    textAlign: 'center',
    margin: '1px 15px',
    padding: 10,
    backgroundColor: 'rgb(180, 138, 78)',
    color: 'rgb(255, 255, 255)',
    width: 300,
    boxShadow: '0 4px 0px rgb(0 0 0 / 55%)',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(160, 120, 68)',
    },
    '&:active': {
      backgroundColor: 'rgb(140, 105, 60)',
    },
    '@media (max-width: 1366px)': {
      width: '100%',
      fontSize: '14px',
    },
  },
  case: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    '@media (max-width: 1366px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
    },
  },
  imglogo: {
    width: 60,
    height: 60,
    border: '1px solid #8170DA',
    borderRadius: 16,
    '@media (max-width: 1366px)': {
      width: '50px',
      height: '50px',
    },
  },
  object: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
    marginRight: '20px',
    '@media (max-width: 1366px)': {
      marginLeft: '0',
      marginRight: '0',
      marginBottom: '10px',
    },
  },
  calendarIcon: {
    marginRight: 8,
    '@media (max-width: 1366px)': {
      marginRight: '5px',
    },
  },
  check: {
    color: '#a48c69',
    '&.Mui-checked': {
      color: 'rgb(180, 138, 78)',
    },
  },
  bar: {
    width: '1px',
    height: '50px',
    backgroundColor: '#cecece',
    margin: '0 10px',
    '@media (max-width: 1366px)': {
      height: '1px',
      width: '100%',
      margin: '10px 0',
    },
  },
});
