/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Methode de transfert
 */
export enum TransactionMethod {
  AVANCE_SOLIDAIRE = 'AVANCE_SOLIDAIRE',
  DON_APPORT = 'DON_APPORT',
  INITIALISATION = 'INITIALISATION',
  PAYER = 'PAYER',
  TRANSFERT_CYTOIENS = 'TRANSFERT_CYTOIENS',
  VERSEMENT_SALAIRE = 'VERSEMENT_SALAIRE',
  //New
  CONSOMMATION_PRESTATION = 'CONSOMMATION_PRESTATION',
  IMPOT_TAXE = 'IMPOT_TAXE',
  SOLIDARITE_CONTRIBUTION = 'SOLIDARITE_CONTRIBUTION',
  CREDIT = 'CREDIT',
  HEBERGEMENT_PRIVATIF = 'HEBERGEMENT_PRIVATIF',
  ASSURANCE = 'ASSURANCE',
  REMBOURSEMENT_PRET = 'REMBOURSEMENT_PRET',
  INVESTISSEMENT_FINANCIER = 'INVESTISSEMENT_FINANCIER',
  DON_POURBOIR = 'DON_POURBOIR',
  FRAIS_FINANCIERE = 'FRAIS_FINANCIERE',
  FOURNITURE_PRESTATION = 'FOURNITURE_PRESTATION',
  SALAIRE_REMUNERATION = 'SALAIRE_REMUNERATION',
  AUTRES = 'AUTRES',
  DEBIT = 'DEBIT',
  HEBERGEMENT_PROFESSIONNEL = 'HEBERGEMENT_PROFESSIONNEL',
  DEBLOCAGE_PRET_AVANCE_REMBOURSABLE = 'DEBLOCAGE_PRET_AVANCE_REMBOURSABLE',
  RETOUR_DES_FONDS_INVESTIS = 'RETOUR_DES_FONDS_INVESTIS',
  DON = 'DON',
  VERSMENT_DIVIDENDES = 'VERSMENT_DIVIDENDES',
  REMBOURSEMENT_PRET_AVANCE_REMBOURSABLE = 'REMBOURSEMENT_PRET_AVANCE_REMBOURSABLE',
}

export interface GetArchiveArgs {
  search?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  skip?: number | null;
  take?: number | null;
  personType?: string | null;
}

export interface GetTransactionArgs {
  currency?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  skip?: number | null;
  take?: number | null;
  transactionType?: string | null;
  transactionMethod?: TransactionMethod | null;
  userId?: string | null;
}

export interface LegalEntityPayload {
  legalEntityTypeId?: string | null;
  denomination?: string | null;
}

export interface TransactionInput {
  amount: number;
  userRecipientId: string;
  transactionMethod?: TransactionMethod;
  currencyId: number;
}

export interface conversionCoinInput {
  affinityCurrencyCode: string;
  amount: number;
}

export interface createCoinInput {
  code: string;
}

export interface loginInput {
  id: string;
  password: string;
}

export interface putIntoCirculationInput {
  coinIds?: (string | null)[] | null;
}

export interface signUpInput {
  lastName?: string | null;
  firstName?: string | null;
  isLegalEntity?: boolean | null;
  legalEntity?: LegalEntityPayload | null;
  id: string;
  password: string;
}

export interface updateSettingInput {
  id: string;
  amountOfCreationCostLegalEntity?: number | null;
  amountOfCreationCostNaturalPerson?: number | null;
  amountOfValidationCost?: number | null;
  payoutAmount?: number | null;
  numberOfValidatorsLegalEntity?: number | null;
  numberOfValidatorsNaturalPerson?: number | null;
}

export interface validateCoinInput {
  coinCode: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
