import React, { useState } from 'react';
import {
  Box,
  Button,
  createMuiTheme,
  TextField,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { useStyles } from '../style';
import CardNotice from '../../../../../common/cardeNotice/CardNotice';
import { TEXT_PERSON_PHYSIQUE_VALIDATION } from '../../../../../constant/TextNotice/TextNotice';
import { CustomModal } from '../../../../../common/customModal/CustomModal';
import { useMutation } from '@apollo/client';
import { VALIDATE_COIN } from '../../../../../graphql/validateCoin/mutation';
import {
  validateCoin,
  validateCoinVariables,
} from '../../../../../graphql/validateCoin/__generated__/validateCoin';
import { CustomSnackBar } from '../../../../../common/CustomSnackBar/CustomSnackBar';
import { CustomBackdrop } from '../../../../../common/BackDrop/BackDrop';

const CreationPPValidation: React.FC = () => {
  const style = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [monnaie, setMonnaie] = useState('');
  const [message, setMessage] = useState('');
  const [type, setType] = useState<string>('');
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#B48A4E',
      },
    },
  });

  const [Dovalidate, { loading: loadingValidate, error: errorValidate }] =
    useMutation<validateCoin, validateCoinVariables>(VALIDATE_COIN, {
      onCompleted: (data) => {
        setMessage('Validation réussie !');
        setType('success');
        setOpen(true);
      },
      onError: (errors) => {
        setMessage('Erreur lors de la validation.');
        setType('error');
        setOpen(true);
      },
    });

  const handleClick = () => {
    if (monnaie === '') {
      setType('error');
      setMessage('Veuillez entrer le nom de la monnaie.');
      setOpen(true);
    } else {
      setMessage('');
      Dovalidate({
        variables: {
          validateCoinInput: {
            coinCode: monnaie,
          },
        },
      });
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMonnaie(e.target.value.trim());
  };
  return (
    <>
      <CustomModal
        open={open}
        setOpen={setOpen}
        maxWidth="sm"
        onClick={() => setOpen(false)}
      >
        <Box>
          {errorValidate
            ? errorValidate.message
            : 'Validation de la monnaie réussie'}
        </Box>
      </CustomModal>
      <Typography className={style.textPM}>
        Je suis une personne physique (citoyen) et l'un des validateurs de cette
        création. Je <br />
        demande la conversion de{' '}
      </Typography>
      <Box className={style.flexContainer}>
        <Typography>
          <span className={style.typography}>1 EU - coin</span> en{' '}
          <span className={style.typography}>1 EU - </span>
        </Typography>
        <Box>
          <TextField
            className={style.textField}
            type="text"
            name="monnaie"
            label="Nom de votre monnaie"
            variant="outlined"
            value={monnaie}
            onChange={handleInputChange}
          />
        </Box>
      </Box>
      <Typography className={style.textPM}>
        et leur transfert vers le compte du gestionnaire des monnaies
        "4MP"(prestataire agréé).
      </Typography>
      <Box className={style.buttonContainer}>
        <ThemeProvider theme={theme}>
          <Button
            onClick={handleClick}
            color="primary"
            variant="contained"
            size="large"
            className={style.btnConfirmer}
            disabled={loadingValidate}
          >
            {loadingValidate
              ? 'Validation en cours...'
              : 'TRANSFERT/VALIDATION'}
          </Button>
        </ThemeProvider>
      </Box>
      <Box className={style.cardNotice}>
        <CardNotice width="80%" children={TEXT_PERSON_PHYSIQUE_VALIDATION} />
      </Box>
      <CustomSnackBar
        {...{ open: open, setOpen: setOpen, message: message, type: type }}
      />
      <CustomBackdrop {...{ open: loadingValidate }} />
    </>
  );
};

export default CreationPPValidation;
