import { createStyles, makeStyles, Theme } from '@material-ui/core';
//import { isWhiteSpaceLike } from "typescript";

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    rootContainer: {
      display: 'flex',
      flexDirection: 'column',
      // height: '100vh',
      // minHeight: '100%',
      margin: 0,
      padding: '22px 28px 0',
      width: '100%',
      '@media (max-width: 1023px)': {
        height: '100vh',
        padding: '12px 14px 0',
      },
      '@media (max-height: 767px)': {
        //overflowY: "auto",
      },

      '& .mainContentBoxWrapper': {
        '&.scrollBarWrap': {
          height: 'calc(100vh - 250px)',
        },
        '& > .simplebar-track.simplebar-horizontal': {
          display: 'none',
        },
        '@media (min-height: 800px)': {
          '& > .simplebar-track': {
            '&.simplebar-vertical': {
              display: 'none',
            },
          },
          '& > .simplebar-placeholder': {
            height: 'auto !important',
            display: 'none',
          },
          '& > .simplebar-mask, .simplebar-offset': {
            '@media (max-height: 699px)': {
              position: 'static',
            },
          },
        },
        '&.ps': {
          '& .ps__rail-x': {
            display: 'none',
          },
          '& .ps__rail-y': {
            '@media (min-height: 700px)': {
              height: 'calc(100vh - 220px)',
            },
          },
        },
      },
    },
    transfertContainer: {
      marginTop: 40,
      border: '1px solid #E9EAEC',
      borderRadius: 6,
      height: 'auto',
      maxHeight: 560,
      maxWidth: 797,
      margin: '20px auto 0',
      padding: '20px 32px',
      width: 'calc(100% - 40px)',
      '@media (min-height: 768px)': {
        height: 560,
        margin: '40px auto 0',
        padding: '40px 65px',
      },
      '@media (max-width: 1023px)': {
        height: 560,
        maxHeight: 'initial',
        padding: '20px 32px',
      },
      '@media (max-width: 767px)': {
        margin: '20px auto 0',
        padding: '10px 16px',
      },
      '@media (max-width: 575px)': {},
      '& *': {
        boxSizing: 'border-box',
      },
    },
    cardContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '20px 0',
      width: '90%',
    },
    card: {
      width: '50%',
      border: '1px solid #D3D3D3',
      borderRadius: '8px',
      overflow: 'hidden',
      padding: '0 15px',
      margin: '0 40px',
    },
    cardContent: {
      padding: _theme.spacing(2),
    },
    typography: {
      fontSize: '16px',
    },
  }),
);
