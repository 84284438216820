import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'row',
    },
    leftColumn: {
      borderRight: '1px solid #E9EAEC',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      flex: '1 0 95px',
      padding: '20px 0 0',
      position: 'relative',
      width: 95,
      '@media (max-width: 575px)': {
        flexBasis: '55px',
        width: 55,
      },
    },
    tabs: {
      borderRight: '1px solid #E9EAEC',
      flex: '1 0 95px',
      padding: '20px 0 15px',
      '& .MuiTabs-flexContainerVertical': {
        alignItems: 'center',
        height: '100%',
      },
      '& .MuiTab-labelIcon': {
        color: '#A8AAB3',
        fontSize: 12,
        lineHeight: '16px',
        minHeight: 'auto',
        width: 'auto',
        marginBottom: 60,
        '&:last-of-type': {
          marginBottom: 0,
        },
        '@media (min-width: 600px)': {
          minWidth: 0,
          paddingBottom: 0,
          paddingTop: 0,
          textTransform: 'none',
        },
        '&.Mui-selected': {
          color: '#4B7FD5',
          textTransform: 'none',
        },
        '& .MuiTab-wrapper': {
          fontSize: 12,
        },
        '& .MuiSvgIcon-root': {
          fontSize: '1.5em',
        },
      },
    },
    '& .MuiTabs-indicator': {
      backgroundcolor: '#F8B83C',
      borderRadius: 10,
      width: 3,
    },
    logo: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      //marginTop: 14,
      height: 64,
      margin: '0 auto 50px',
      width: 64,
      '@media (max-width: 575px)': {
        height: 54,
        width: 54,
      },
      '& a': {
        textDecoration: 'none',
      },
    },
    textTypography: {
      fontSize: 18,
      fontWeight: 400,
      width: 50,
      height: 50,
      backgroundColor: ' #4B7FD5',
      borderRadius: 10,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      transform: 'rotate(45deg)',
      color: 'white',
      marginBottom: 0,
      '@media (max-width: 575px)': {
        fontSize: 14,
        height: 40,
        width: 40,
      },
      '& > span': {
        transform: 'rotate(-45deg)',
      },
      '& strong': {
        fontWeight: 700,
      },
    },
    listMenuCntr: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    listMenu: {
      borderRight: 'none',
      flex: '1 1 100%',
      position: 'relative',
      '& .MuiList-padding': {
        height: '100%',
        padding: 0,
      },
      '& .MuiListItem-root': {
        width: '100%',
      },
      '& .MuiListItem-gutters': {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '& .menuCnt': {
        borderRightColor: 'transparent',
        borderRightStyle: 'solid',
        borderRightWidth: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        zIndex: 0,
        '& .MuiSvgIcon-root': {
          paddingLeft: 0,
          paddingRight: 0,
        },
        '& .MuiListItemText-root': {
          fontSize: 12,
          lineHeight: '16px',
          marginBottom: 0,
          marginTop: 5,
          '@media (max-width: 575px)': {
            display: 'none',
          },
        },
        '& .MuiListItemText-primary': {
          fontSize: 'inherit',
        },
      },
    },
    activ: {
      color: '#B48A4E',
      borderRight: 'none',
      '& .menuCnt': {
        borderRightColor: '#B48A4E',
        borderRightWidth: '4px', // spécifiez l'épaisseur souhaitée ici
        borderRightStyle: 'solid', // assurez-vous que la bordure est visible
      },
      '& .menuCnt img': {
        filter:
          'invert(38%) sepia(52%) saturate(3188%) hue-rotate(16deg) brightness(95%) contrast(92%)',
      },
    },
    inactive: {
      color: '#A8AAB3',
      //borderRightColor: "transparent",
    },
    menuItem: {
      marginBottom: 44,
      '@media (max-width: 575px)': {
        marginBottom: 24,
      },
      '&.last-of-type': {
        marginBottom: 0,
      },
    },
    deconnect: {
      cursor: 'pointer',
      position: 'absolute',
      //bottom: 14,
      bottom: 0,
      left: 0,
      display: 'flex',

      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: 94,
      padding: '15px 10px 10px',
      width: '100%',
      '@media (max-width: 575px)': {
        maxWidth: '100%',
      },
      '& .MuiIconButton-root': {
        paddingBottom: 0,
      },
      '& .MuiTypography-root': {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '0.875em',
      },
      '& .MuiSvgIcon-root': {
        color: '#212226',
        height: 24,
        width: 24,
        '& path': {
          fill: '#212226',
        },
      },
      '& .MuiListItemText-root': {
        marginBottom: 0,
        '@media (max-width: 575px)': {
          display: 'none',
        },
      },
      '& .MuiButton-text, .MuiListItemText-primary': {
        fontSize: 12,
        lineHeight: '16px',
        marginTop: 4,
        paddingBottom: 0,
        paddingTop: 0,
        textTransform: 'none',
      },
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    deconnectCnt: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rightColumn: {
      flex: '1 1 100%',
      overflow: 'hidden',
    },
    noFound: {
      color: '#212226',
      fontWeight: 700,
      textAlign: 'center',
    },
  }),
);
