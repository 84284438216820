import gql from 'graphql-tag';

export const COIN_PERSON_OPTIONS = gql`
  query Coin_Person_Options {
    coinPersonOptions {
      value
      label
    }
  }
`;
