export const LOGIN_URL = '/';
export const ACCUEIL_URL = '/accueil';
export const TRANSFERT_URL = '/accueil/transfert/';
export const HISTORY_URL = '/history';
export const FAQ_URL = '/faq';
export const SIGN_UP_URL = '/inscription';
export const CREATION_URL = '/création';
export const CONVERSION_URL = '/convertion';
export const SIGNUP_URL_ID = '/inscription/create_id';
export const COMMISSIONING_URL = '/commissionning';
export const ARCHIVE_URL = '/archive';
