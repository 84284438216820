import { gql } from "@apollo/client";

export const ARCHIVE_FRAGMENTS_INFO = gql`
  fragment archiveInfo on ArchiveResult {
    total
    data {
      id
      creationPaidAmount
      validationPaidAmount
      numberOfValidations
      numberOfPayments
      paymentAmount
      coinId
      creatorId
      coinName
      shortCode
      color
      coinCreatedAt
      creator
      type
      createdAt
    }
  }
`;
