export const TOKEN = 'token';
export const API_URL = process.env.REACT_APP_API_URL;
export const WS_API_URL = process.env.REACT_APP_WS_URL;
export const TEXT_TRANSFERT_ECHOU =
  "Votre transfert n'a pas été effectué. Merci de contacter l'administrateur.";
export const TEXT_TRANSFERT_SUCCESS =
  'Votre transfert a été effectué avec succès.';
export const TEXT_HISTORIQUE =
  "Consultez vos transferts de valeur effectués avec d'autres utilisateurs.";
export const TEXT_CONSULTATION =
  "Vous pouvez maintenant transférer des valeurs vers d'autres utilisateurs.";
export const TEXT_LOGIN =
  'Entrez votre identifiant et votre mot de passe pour accéder à votre espace privé.';
export const TEXT_SIGN_UP =
  'Saisissez vos informations pour créer votre compte';
export const TEXT_SIGN_UP_PERSON = 'Je suis une personne :';
export const TEXT_SIGN_UP_SUCCESS =
  'Veuillez mémoriser et utiliser désormais ces 2 données pour vous connecter à votre compte de test Chain4Wallet :';

export const TEXT_CREATION =
  'Vous pouvez créer une monnaie à partir de cette page';
export const TEXT_ARCHIVE =
  "Consulter la liste d'archive des monnaies affinitaires";
export const TEXT_CONVERSION = 'Choisissez parmi les 2 méthodes de conversion';
export const INVALID_FORMAT_DATE = 'Format de date non valide';
export const INVALID_DATE_MAX =
  'La date ne doit pas être postérieure à la date actuelle';
export const SUPERIORE_DATE =
  'La date "AU" doit être supérieur à la presente date!!';
export const INFERIOR_DATE =
  'La date "DU" doit être inférieur à la presente date!!';
export const AMOUNT_NOT_AVAILABLE =
  'Montant non disponible, Veuillez choisir un autre montant ';
export const USER_UNKNOWN = 'Bénéficiaire inconnu dans la Chain 4 Test';
export const TEXT_DUPLICATED_NAME = 'Votre identité sera donc';
export const COUNTRY_CODE = '(FRA)';
export const WALLET_SERVER = 'Chain4Test';
export const TRANSACTION = {
  sentLabel: 'SENT',
};
export const TEXT_ACCES = 'Vos accès!';
export const TEXT_INTRO_ACCES =
  'Veuillez mémoriser et utiliser désormais ces 2 données pour vous connecter  à votre compte de test Chain4test :';
