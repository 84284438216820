import { TransactionMethod } from "../../types/graphql-global-types";

export const PERIODE_OPTIONS = [
  { value: 1, textName: "Année" },
  { value: 2, textName: "Mois" },
  { value: 3, textName: "Date" },
];

export const TRANSACTION_OPTIONS = [
  { value: "all", textName: "Tous les sens" },
  { value: "SENT", textName: "Débit" },
  { value: "RECEIVED", textName: "Crédit" },
];

export const PERSON_TYPE_OPTIONS = [
  { value: "all", textName: "Tous les types" },
  { value: "PP", textName: "Physique" },
  { value: "PM", textName: "Morale" },
];

export const VALUES_OPTIONS = [
  { value: 0, textName: "Toutes les valeurs" },
  { value: 1, textName: "Coins" },
  { value: 2, textName: "CCoins" },
  { value: 3, textName: "CCCoins" },
];

export const TRANSACTION_METHOD_OPTIONS = [
  { value: "all", textName: "Toutes les transactions" },
  { value: TransactionMethod.PAYER, textName: "Paiement" },
  { value: TransactionMethod.DON_APPORT, textName: "Don ou Apport" },
  {
    value: TransactionMethod.TRANSFERT_CYTOIENS,
    textName: "Transfert citoyens",
  },
  { value: TransactionMethod.AVANCE_SOLIDAIRE, textName: "Avance solidaire" },
  { value: TransactionMethod.VERSEMENT_SALAIRE, textName: "Versement salaire" },
];
