import { gql } from "@apollo/client";

export const TRANSACTION_HISTORY_FRAGMENT_INFOS = gql`
  fragment transactionHistoryFragmentInfos on TransactionHistory {
    id
    transactionMethod
    transaction_hash
    createdAt
    from
    to
    label
    amount
    currency
    code
    shortCode
    color
    sender {
      id
      name
      lastName
      userName
      legalEntity {
        id
        denomination
      }
    }
    recipient {
      id
      name
      lastName
      userName
      legalEntity {
        id
        denomination
      }
    }
  }
`;
