import { Box, Typography } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import user from '../../../assets/icon/user.svg';
import { AuthContext } from '../../../provider/Authprovider';
import { useStyles } from './Balance/style';
import { useApolloClient, useQuery, useSubscription } from '@apollo/client';
import BigNumber from 'bignumber.js';
import { GET_TOTAL } from '../../../graphql/Balances/queries';
import {
  TOTAL,
  TOTAL_ChainAvailableAmount,
} from '../../../graphql/Balances/__generated__/TOTAL';
import { onNewUserSignUp } from '../../../graphql/SignUp/__generated__/onNewUserSignUp';
import { OnNewUserSignUp } from '../../../graphql/SignUp/subscription';
import {
  capitalizeFirstLetter,
  formatUsernameWithCountryCode,
} from '../../../utils/helpers';

interface HeaderProps {
  titleheader: string;
  subtitle: string;
  texte: string;
  withUserId?: boolean;
}

const Header: FC<HeaderProps> = ({
  titleheader,
  texte,
  subtitle,
  withUserId,
}) => {
  const style = useStyles();
  const client = useApolloClient();
  const { logout, userInfos } = useContext(AuthContext);
  if (
    (userInfos && !userInfos.userName) ||
    localStorage.getItem('isFirstTab') === 'true'
  ) {
    logout();
  }

  const { data: all } = useQuery<TOTAL, TOTAL_ChainAvailableAmount>(GET_TOTAL);
  const total = all?.ChainAvailableAmount;

  useSubscription<onNewUserSignUp>(OnNewUserSignUp, {
    onSubscriptionData: (data) => {
      if (
        data &&
        data.subscriptionData &&
        data.subscriptionData.data &&
        data.subscriptionData.data.onNewUserSignUp
      ) {
        const cachedData = client.readQuery<TOTAL>({
          query: GET_TOTAL,
        });

        client.writeQuery<TOTAL>({
          query: GET_TOTAL,
          data: {
            ...cachedData,
            ChainAvailableAmount:
              data.subscriptionData.data.onNewUserSignUp.ChainAvailableAmount,
          },
        });
      }
    },
  });

  const v =
    total &&
    total.reduce((total: any, cur: any) => {
      for (let data of cur.data) {
        total.set(
          data.currency,
          new BigNumber(total.get(data.currency) || 0)
            .plus(new BigNumber(data.value))
            .toNumber(),
        );
      }
      return total;
    }, new Map());

  let x: any = [];

  v &&
    v.forEach((i: number, key: number) => {
      x.push({
        typename: 'total',
        currentId: key,
        value: i,
      });
    });

  return (
    <Box className={style.wrapHeader}>
      <Box className={style.boxheader}>
        <Box className={style.boxTitle}>
          <Typography variant={'h4'} className={style.textAccueil}>
            {titleheader}
          </Typography>
        </Box>
        <Box {...{ display: 'flex' }} className="userProfil">
          <Box className="user_name">
            <Typography variant={'h5'}>Bonjour,</Typography>
            <Typography variant={'h4'}>
              {userInfos && userInfos.isAdmin
                ? userInfos.userName?.toUpperCase()
                : formatUsernameWithCountryCode(
                    capitalizeFirstLetter(
                      userInfos?.userName || ''
                    ),
                  )
              }
            </Typography>
          </Box>

          <Box className="user_avatar">
            <img {...{ src: user, alt: 'user icon', className: 'user-icon' }} />
          </Box>
        </Box>
      </Box>
      <Box className={style.boxintro}>
        <Typography variant="h2">{subtitle}</Typography>
        <Typography variant={'h3'} color="textSecondary">
          {texte}
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;
