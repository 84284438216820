import { useQuery } from '@apollo/client';
import React, { createContext, FC, useState } from 'react';
import { CustomBackdrop } from '../common/BackDrop/BackDrop';
import { CustomSnackBar } from '../common/CustomSnackBar/CustomSnackBar';
import { TOKEN } from '../constant';
import { DO_GET_ME } from '../graphql/Me/query';
import { Me } from '../graphql/Me/__generated__/Me';
import { setUser } from './localesStorage';
import { login_login } from '../graphql/Login/__generated__/login';

export interface IUserPartial {
  isAdmin: boolean;
  userName: string;
}

interface AuthContextInterface {
  login: (data: login_login) => void;
  logout: () => void;
  token: string | null;
  setToken: (token: string) => void;
  userInfos: IUserPartial | null;
  setUserInfos: (user: IUserPartial | null) => void;
  onShowSnackBar: (
    message: string,
    typeSnackBar: string,
    openSnack: boolean,
  ) => void;
}

export const AuthContext = createContext<AuthContextInterface>({
  login: () => {},
  logout: () => {},
  token: '',
  setToken: () => {},
  userInfos: null,
  setUserInfos: () => {},
  onShowSnackBar: () => {},
});

export const AuthProvider: FC = ({ children }) => {
  const [token, setToken] = useState<string | null>(null);
  const [userInfos, setUserInfos] = useState<IUserPartial | null>(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setmessage] = React.useState('');
  const [typeSnack, settypeSnack] = React.useState('');

  const { data: dataMe, loading: loadingMe, refetch } = useQuery<Me>(DO_GET_ME);

  if (dataMe) {
    setUser(dataMe.me);
  }
  if (loadingMe) {
    return <CustomBackdrop {...{ open: loadingMe }} />;
  }
  return (
    <AuthContext.Provider
      {...{
        value: {
          login: (data: login_login) => {
            localStorage.setItem(TOKEN, data.accessToken || '');
            localStorage.setItem('isFirstTab', 'false');
            setToken(data.accessToken);
            if (data.userPartialInfos) {
              const {isAdmin, userName} = data.userPartialInfos
              setUserInfos({
                isAdmin: isAdmin || false,
                userName: userName || ''
              })
            }
            refetch();
          },
          logout: () => {
            localStorage.removeItem(TOKEN);
            localStorage.clear();
            setToken(null);
            window.location.reload();
          },
          onShowSnackBar: (message, typeSnack, boolean) => {
            setOpenSnack(boolean);
            setmessage(message);
            settypeSnack(typeSnack);
          },
          token,
          userInfos,
          setToken: (token: string) => setToken(token),
          setUserInfos: (user: IUserPartial | null) => setUserInfos(user)
        },
      }}
    >
      <CustomSnackBar
        {...{
          open: openSnack,
          setOpen: setOpenSnack,
          message: message,
          type: typeSnack,
        }}
      />
      {children}
    </AuthContext.Provider>
  );
};
