import React, { useState } from 'react';
import Header from '../acceuil/Header';
import { TEXT_CREATION } from '../../../constant';
import { Box } from '@material-ui/core';
import { useStyles } from './style';
import CreationPM from './creationPM/CreationPM';
import CreationPP from './creationPP/CreationPP';
import { getUser } from '../../../provider/localesStorage';
const Creation: React.FC = () => {
  const user = getUser();
  const style = useStyles();
  return (
    <Box className={style.rootContainer}>
      <Header
        {...{
          titleheader: 'Création',
          subtitle: 'Création de monnaie',
          texte: TEXT_CREATION,
        }}
      />
      {user?.legalEntity?.id ? <CreationPM /> : <CreationPP />}
    </Box>
  );
};
export default Creation;
