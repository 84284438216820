import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    transferContainer: {
      marginTop: 40,
      border: '1px solid #E9EAEC',
      borderRadius: 6,
      height: 'auto',
      maxHeight: 560,
      maxWidth: '100%',
      margin: '12px auto 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '25px',
      width: 'calc(100% - 1px)',
      '@media (min-height: 768px)': {
        height: 560,
        margin: '12px auto 0',
        padding: '40px 50px',
      },
      '@media (max-width: 1366px)': {
        padding: '30px 20px',
        maxHeight: 'none',
      },
      '@media (max-width: 1024px)': {
        padding: '20px 15px',
        height: 'auto',
      },
      '@media (max-width: 768px)': {
        padding: '15px 10px',
      },
      '@media (max-width: 575px)': {
        padding: '10px 5px',
      },
      '& *': {
        boxSizing: 'border-box',
      },
    },
    containtWrappe: {
      width: '60%',
      maxWidth: '1000px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 8,
      '@media (max-width: 1366px)': {
        width: '80%',
      },
      '@media (max-width: 768px)': {
        width: '90%',
      },
      '@media (max-width: 575px)': {
        width: '100%',
      },
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: 20,
      flexWrap: 'wrap',
    },
    cardNotice: {
      marginLeft: 195,
      width: '100%',
      '@media (max-width: 1600px)': {
        marginLeft: '10%',
      },
      '@media (max-width: 1366px)': {
        marginLeft: '24%',
      },
      '@media (max-width: 1023px)': {
        marginLeft: 0,
      },
    },
    typography: {
      fontWeight: 600,
      marginRight: '10px',
      fontSize: 20,
      '@media (max-width: 1366px)': {
        fontSize: 18,
      },
      '@media (max-width: 768px)': {
        fontSize: 16,
      },
      '@media (max-width: 575px)': {
        fontSize: 14,
      },
    },
    textField: {
      width: 500,
      background: '#FFFFFF',
      opacity: 1,
      height: 45,
      margin: '0 0 0px',
      '@media (max-width: 1366px)': {
        width: '400px',
      },
      '@media (max-width: 1024px)': {
        width: '300px',
      },
      '@media (max-width: 768px)': {
        width: '100%',
      },
      '&:last-of-type': {
        marginBottom: 0,
      },
      '& label': {
        color: '#A8AAB3',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
      },
      '& .MuiOutlinedInput-root': {
        '&:hover': {
          borderColor: '#B48A4E',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#B48A4E',
          },
        },
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 14px) scale(1)', // Position initiale
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -10px) scale(0.75)', // Ajustement vertical
        },
      },
      '& .MuiInputLabel-formControl': {
        fontFamily: 'Poppins, sans-serif',
      },
      '& .MuiOutlinedInput-input': {
        height: 45,
        paddingLeft: 10,
        width: '100%',
      },
      '& input': {
        borderRadius: 6,
        fontSize: '1.1em',
        '&:focus': {
          outline: 'none',
        },
      },
    },
    textPM: {
      fontSize: 20,
      marginBottom: 20,
      '@media (max-width: 1366px)': {
        fontSize: 18,
      },
      '@media (max-width: 768px)': {
        fontSize: 16,
      },
      '@media (max-width: 575px)': {
        fontSize: 14,
      },
    },
    btnConfirmer: {
      backgroundColor: '#B48A4E',
      color: '#fff',
      boxShadow: '0 3px 3px rgb(0 0 0 / 51%)',
      padding: '10px 20px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginBottom: 20,
    },
  }),
);
