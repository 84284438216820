import React, { useState } from 'react';
import {
  Box,
  Button,
  createMuiTheme,
  TextField,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { useStyles } from './style';
import CardNotice from '../../../../common/cardeNotice/CardNotice';
import { TEXT_PERSON_MORALE } from '../../../../constant/TextNotice/TextNotice';
import { useMutation } from '@apollo/client';
import { CREATE_COIN } from '../../../../graphql/CreateCoin/mutation';
import {
  createCoin,
  createCoinVariables,
} from '../../../../graphql/CreateCoin/__generated__/createCoin';
import { CustomSnackBar } from '../../../../common/CustomSnackBar/CustomSnackBar';
import { CustomBackdrop } from '../../../../common/BackDrop/BackDrop';
import { CustomModal } from '../../../../common/customModal/CustomModal';

const CreationPM: React.FC = () => {
  const style = useStyles();
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#B48A4E',
      },
    },
  });

  // State
  const [monnaie, setMonnaie] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  // Mutation
  const [Docreate, { loading: loadingCreate }] = useMutation<
    createCoin,
    createCoinVariables
  >(CREATE_COIN, {
    onCompleted: () => {
      setType('success');
      setMessage('Création réussie !');
      setOpen(true);
      setMonnaie('');
    },
    onError: (errors) => {
      setType('error');
      setMessage(errors.message);
      setOpen(true);
    },
  });

  // Handlers
  const handleClick = () => {
    if (monnaie === '') {
      setType('error');
      setMessage('Veuillez entrer le nom de la monnaie.');
      setOpen(true);
    } else {
      setMessage('');
      Docreate({
        variables: {
          createCoinInput: {
            code: monnaie,
          },
        },
      });
    }
    setMonnaie('');
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMonnaie(e.target.value.trim());
  };
  return (
    <Box className={style.transferContainer}>
      <CustomModal open={open} setOpen={setOpen} maxWidth="sm">
        <Box>{message}</Box>
      </CustomModal>

      <Box className={style.containtWrappe}>
        <Typography className={style.textPM}>
          Je suis une personne morale (société, association, administration,
          etc...) et l'initiateur de <br />
          cette création. Je demande la conversion de
        </Typography>

        <Box className={style.flexContainer}>
          <Typography>
            <span className={style.typography}>1000 UE-coins</span> en{' '}
            <span className={style.typography}>1000 UE-</span>
          </Typography>

          <Box>
            <TextField
              className={style.textField}
              type="text"
              name="monnaie"
              label="Nom de votre monnaie"
              variant="outlined"
              value={monnaie}
              onChange={handleInputChange}
            />
          </Box>
        </Box>

        <Typography className={style.textPM}>
          et leur transfert vers le compte du gestionnaire des monnaies "4MP"
          (prestataire agréé).
        </Typography>

        <Box className={style.buttonContainer}>
          <ThemeProvider theme={theme}>
            <Button
              onClick={handleClick}
              color="primary"
              variant="contained"
              size="large"
              className={style.btnConfirmer}
              disabled={loadingCreate}
            >
              {loadingCreate ? 'Transfert en cours...' : 'TRANSFERT/CRÉATION'}
            </Button>
          </ThemeProvider>
        </Box>

        <Box className={style.cardNotice}>
          <CardNotice width="80%" children={TEXT_PERSON_MORALE} />
        </Box>

        <CustomSnackBar
          open={open}
          setOpen={setOpen}
          message={message}
          type={type}
        />

        <CustomBackdrop open={loadingCreate} />
      </Box>
    </Box>
  );
};

export default CreationPM;
